<template>
  <div>
    <b-container fluid class="d-flex m-0 p-0">
      <div class="rectangle"></div>
      <div class="sticky-button">
        <a href="tel:916362609612">
        <img src="../assets/call.png" alt="" /></a>
        <br />
        <a href="https://wa.me/916362609612">
         <img src="../assets/watsapp.png" alt="" /></a>
        <br />
        <a href="mailto:consultant@manedesign.in" >
        <img src="../assets/email.png" alt="" /></a>
        <!-- <button @click="handleClick">Click Me</button> -->
      </div>
    </b-container>
  <!-- Container 1 -->
  <b-container fluid style="position: relative;">
    <b-row class="d-none d-md-block" style="position: relative; z-index: -1; background-color: #ff0000;">
      <img
        src="../assets/bc.png"
        alt=""
        style="margin: 0%; padding: 0%"
      />
    </b-row>
    <b-row class="d-block d-md-none" style="position: relative; z-index: -1">
      <img src="../assets/bc-1.png" alt="" style="margin: 0%; padding: 0%" />
    </b-row>
  </b-container>

  <!-- Container 2 -->
  <b-container fluid style="position: absolute; top: 0; width: 100%; z-index: 1;">
    <div class="position-relative">
      <b-navbar
        toggleable="lg"
        type="light"
        class="mt-3 mt-md-4"
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="1500"
      >
        <!-- Logo -->
        <b-navbar-brand href="#" class="ml-md-4" :to="{ name: 'home' }">
          <img
            src="../assets/logo.png"
            alt="Logo"
            class="navbar-logo"
            style="height: 8rem;"
          />
        </b-navbar-brand>

        <!-- Top-right buttons -->
        <div class="top-right-buttons d-none d-lg-flex">
          <router-link to="/blogs">
            <b-button variant="outline-primary" class="nav-button mr-3">
           <span style="font-size: 15px;color: black;margin-left: 7px;">Blogs</span> 
          </b-button>
          </router-link>
          <router-link to="/projects">
            <b-button variant="outline-primary" class="nav-button">
           <span style="font-size: 15px;color: black;margin-left: 7px;"> View Projects </span> 
          </b-button>
          </router-link>
          
        </div>

        <!-- Navbar toggler for mobile -->
        <b-navbar-toggle target="nav-collapse" class="d-lg-none"></b-navbar-toggle>

        <!-- Navbar collapse for mobile -->
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto d-lg-none">
            <b-nav-item class="my-2">
              <router-link to="/blogs">
              <b-button variant="outline-primary" class="nav-button w-100">
                Blogs
              </b-button>
              </router-link>

            </b-nav-item>
            <b-nav-item class="my-2">
              <router-link to="/projects">
              <b-button variant="outline-primary" class="nav-button w-100">
                View Projects
              </b-button>
              </router-link>
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>

      </b-navbar>

    </div>
    <b-row class="align-items-center mt-4">
      <b-col lg="5" md="6" sm="12" class="mb-4 mb-md-0 text-content">
        <div class="content-wrapper">
          <p
            class="font-weight-bold"
            :style="{
              fontSize: 'clamp(2.4rem, 3vw, 3.6rem)',
              fontWeight: '300',
              lineHeight: '1.2',
              color: '#000000',
              fontFamily: 'Playfair Display'
            }"
          >
            Where Comfort Meets <span :style="{
              fontSize: 'clamp(3.6rem, 5vw, 4.8rem)',
              fontWeight: '700',
              lineHeight: '1.2',
              color: '#000000',
              fontFamily: 'Oswald'
            }"> CONVENIENCE </span>
          </p>
          
          <p :style="{
              fontSize: 'clamp(2.1rem, 2.1vw, 2.6rem)',
              fontWeight: '700',
              lineHeight: '1.2',
              color: '#000000',
              opacity: '70%',
              fontFamily: 'Cedarville Cursive'
            }">Find Your Perfect Space</p>


          
          <!-- <b-button
            class="mt-3 border-0 rounded-0"
            style="background-color: white;"
          >
            <span
              class="d-flex align-items-center"
              style="color: #8da9b2; font-weight: 600;"
              :style="{
                fontSize: 'clamp(0.8rem, 1.5vw, 1.125rem)'
              }"
            >
              About Us
              <img src="../assets/Arrow 1.png" alt="" class="ml-2" style="height: 1em; width: auto;" />
            </span>
          </b-button> -->
        </div>
      </b-col>
      <b-col lg="7" md="6" sm="12" class="mt-4 mt-md-0">
        <img
          src="../assets/logo1.png"
          alt=""
          class="img-fluid responsive-image blog-img-zoom"
        />
      </b-col>
    </b-row>
  </b-container>


  <b-container>
    <b-row>
      <div style="margin-top: 25vh;">
      <p
            :style="{
              fontFamily: 'Barlow',
              fontSize: 'clamp(1.8rem, 2.6vw, 3.2rem)',
              fontWeight: '300',
              lineHeight: '1.4',
              
            }"
          >
            Welcome to the heart of innovative design
            <br class="d-none d-sm-block" />
            We redefine excellence in the world of architectural design & construction
          </p>
          </div>
    </b-row>
  </b-container>


  
  <b-container fluid class="mt-5 pb-2">
    <div class="services-container my-5">
      <b-card no-body class="overflow-hidden" style="max-height: 350px;">
        <b-row>

          <b-col md="6" style="background-color: #2c3e50;">
            
            <b-card-body>
              <b-card-text>
                <b-row>
                  <p class="pp3">
                    Crafting Spaces. Shaping Lives.
                  </p>
                </b-row>

                <p class="pp5">
                  At MANE Designs & Constructions, we craft spaces that tell your story. 
                  Our team of architects and designers transforms visions into reality, 
                  balancing functionality with beauty. From homes to commercial spaces, 
                  we deliver innovative solutions that reflect our clients' unique essence. 
                  We don't just design; we create environments that inspire and enrich lives.
                </p>
              </b-card-text>
            </b-card-body>
          </b-col>

          <b-col md="6" class="why-img-cont">
            <img
              src="../assets/LANDING PAGE/b1.jpg"
              alt=""
              class="rounded-0 why-img" style="margin-left: -15px;"
            />
          </b-col>
        </b-row>
      </b-card>
      
    </div>
  </b-container>




  <!-- New Content -->
  <b-container fluid style="margin-top: -50px; margin-bottom: 120px;">
    <div class="services-container my-5">
      <h2 class="text-center mb-4 our-services head1 blog-head">Our Services</h2>
      <div class="services-grid">
        <div v-for="(service, index) in services" :key="index" class="service-item">
          <div
            class="image-card"
            @mouseover="service.hover = true"
            @mouseleave="service.hover = false"
          >
            <div class="image-container" :class="{ hover: service.hover }" style="border-radius: 5px;">
              <img :src="service.image" class="card-img" alt="Service Image" />
              <div class="card-overlay">
                <h3 class="card-title" style="font-family: Lora;">
                  {{ service.title }}
                </h3>
                <div class="card-content">
                  <p style="font-family: Hind Madurai; font-size: 16px; font-weight: 300;">
                    {{ service.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-container>



    <b-container fluid class="mt-5 pb-5" style="background-color: #2B2B4F;">
            <div class="container">
               <div class="row g-5">
                  <div class="col-md-3 col-6">
                     <div class="text-center">
                        <h2 style="color: azure; font-family: Jost; font-weight: 600;">18+</h2>
                        <h5 style="color: thistle; font-family: Hind Madurai;">Years of Experience</h5>
                     </div>
                  </div>
                  <div class="col-md-3 col-6">
                     <div class="text-center">
                        <h2 style="color: azure; font-family: Jost; font-weight: 600;">20+</h2>
                        <h5 style="color: thistle; font-family: Hind Madurai;">Experts</h5>
                     </div>
                  </div>
                  <div class="col-md-3 col-6">
                     <div class="text-center">
                        <h2 style="color: azure; font-family: Jost; font-weight: 600;">2.25 Lakh</h2>
                        <h5 style="color: thistle; font-family: Hind Madurai;">sq ft Built</h5>
                     </div>
                  </div>
                  <div class="col-md-3 col-6">
                     <div class="text-center">
                        <h2 style="color: azure; font-family: Jost; font-weight: 600;">40+</h2>
                        <h5 style="color: thistle; font-family: Hind Madurai;">Locations</h5>
                     </div>
                  </div>
               </div>
            </div>
         </b-container>




<!--     
    <section class="my-xl-7 py-5" data-cue="fadeIn">
            <div class="container">
               <div class="row">
                  <div class="col-lg-8 offset-lg-2 col-md-12 col-12">
                     <div class="text-center mb-6 mb-lg-9">
                        <small class="text-primary text-uppercase ls-md fw-semibold">features</small>
                        <h2 class="my-3">Story Elements That Resonate</h2>
                        <p class="mb-0 lead">Craft compelling narratives that captivate hearts and minds. With peak STORIES, your message becomes an unforgettable journey of inspiration and growth.</p>
                     </div>
                  </div>
               </div>
               <div class="row g-lg-7 gy-5">
                  <div class="col-lg-3 col-md-6">
                     <div class="text-center text-md-start" data-cue="zoomIn">
                        <div class="icon-lg icon-shape rounded bg-primary bg-opacity-10 border border-primary-subtle mb-5">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-check-circle text-primary" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                              <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                           </svg>
                        </div>

                        <h4>Bilingual Storytelling</h4>
                        <p class="mb-0">Captivating narratives in Kannada and English.</p>
                     </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                     <div class="text-center text-md-start" data-cue="zoomIn">
                        <div class="icon-lg icon-shape rounded bg-primary bg-opacity-10 border border-primary-subtle mb-5">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-flag-fill text-primary" viewBox="0 0 16 16">
                              <path
                                 d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                           </svg>
                        </div>

                        <h4>Inspirational Content</h4>
                        <p class="mb-0">Stories that motivate and encourage personal growth.</p>
                     </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                     <div class="text-center text-md-start" data-cue="zoomIn">
                        <div class="icon-lg icon-shape rounded bg-primary bg-opacity-10 border border-primary-subtle mb-5">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-clock-history text-primary" viewBox="0 0 16 16">
                              <path
                                 d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z" />
                              <path d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z" />
                              <path d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z" />
                           </svg>
                        </div>

                        <h4>Entrepreneurial Focus</h4>
                        <p class="mb-0">Showcasing journeys of business success and innovation.</p>
                     </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                     <div class="text-center text-md-start" data-cue="zoomIn">
                        <div class="icon-lg icon-shape rounded bg-primary bg-opacity-10 border border-primary-subtle mb-5">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-layout-text-window-reverse text-primary" viewBox="0 0 16 16">
                              <path
                                 d="M13 6.5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5zm0 3a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h5a.5.5 0 0 0 .5-.5zm-.5 2.5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1h5z" />
                              <path
                                 d="M14 0a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12zM2 1a1 1 0 0 0-1 1v1h14V2a1 1 0 0 0-1-1H2zM1 4v10a1 1 0 0 0 1 1h2V4H1zm4 0v11h9a1 1 0 0 0 1-1V4H5z" />
                           </svg>
                        </div>

                        <h4>Multi-format Delivery</h4>
                        <p class="mb-0">Engaging content across various media platforms.</p>
                     </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                     <div class="text-center text-md-start" data-cue="zoomIn">
                        <div class="icon-lg icon-shape rounded bg-primary bg-opacity-10 border border-primary-subtle mb-5">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-paperclip text-primary" viewBox="0 0 16 16">
                              <path
                                 d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                           </svg>
                        </div>

                        <h4>Cultural Relevance</h4>
                        <p class="mb-0">Stories tailored to resonate with diverse audiences.</p>
                     </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                     <div class="text-center text-md-start" data-cue="zoomIn">
                        <div class="icon-lg icon-shape rounded bg-primary bg-opacity-10 border border-primary-subtle mb-5">
                           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-people-fill text-primary" viewBox="0 0 16 16">
                              <path
                                 d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7Zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-5.784 6A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216ZM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z" />
                           </svg>
                        </div>

                        <h4>Interactive Storytelling</h4>
                        <p class="mb-0">Encouraging audience participation and connection.</p>
                     </div>
                  </div>
               </div>
            </div>
         </section> -->


    
<b-container class="mt-5 mb-5">
  <p class="head1 blog-head mt-5 mb-4">
    Why Choose Us
  </p>

  <p style="font-family: Lora; font-size: 24px; font-weight: 400; text-align: start">
    Experience Excellence in Every Detail
  </p>

  <p style="font-family: Hind Madurai; font-size: 18px; text-align: start;">
    At MANE Designs & Constructions, we create harmonious spaces that blend comfort, beauty, and quality.
    <br>
    From expert consultations to full-scale execution, we handle everything - architectural planning, interior design, construction, and remounting finishing work.
    <br>
    Experience a seamless journey from inspiration to realization, where every detail is thoughtfully crafted to enhance your space.
  </p>

  <b-row style="margin-top: 30px">
    <b-col v-for="(step, index) in approachSteps" :key="index" md="6">
      <b-row style="margin-top: 20px;">
        <b-col v-for="(item, itemIndex) in step.items" :key="itemIndex">
          <img :src="item.image" alt="" :style="{ width: item.width }" />
          <p style="font-size: 15px; font-weight: 600; margin-top: 15px">
            {{ item.text }}
          </p>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</b-container>



<b-container class="test"  style="background-color: #ebf7fa !important;" fluid>
      <p style="margin-top: 20px; padding-top: 50px; color: black; font-family: Lora; font-size: 24px; font-weight: 400;">
        The <strong> MANE </strong> Experience: Love and Smiles from Our Clients.
      </p>
      <div class="testimonial-container">
        <div class="d-flex justify-content-center align-items-center">
          <img
            src="../assets/larr.png"
            alt=""
            @click="prev"
            style="cursor: pointer"
          />

          <!-- Mobile View -->
<div class="testimonial-cards d-flex d-md-none">
  <b-col cols="12" class="testimonial" v-for="(testimonial, index) in visibleMessages" :key="index">
    <b-card
      class="h-100"
      style="background-color: #ebf7fa !important;"
    >
      <p class="head2" style="font-family: Lora; font-weight: bold;">{{ testimonial.name }}</p>
      <p style="font-family: Hind Madurai; font-size: 18px;">
        <img src="../assets/usc.png" alt="" style="height: 5vh;"/>
        {{ testimonial.message }}
      </p>
    </b-card>
  </b-col>
</div>


          <!-- Desktop View -->
          <div class="testimonial-cards d-none d-md-flex">
            <b-col
              v-for="(testimonial, index) in visibleMessages"
              :key="index"
              cols="12"
              md="4"
              class="testimonial"
            >
              <b-card
                class="h-100"
                style="background-color: #ebf7fa !important;border:none !important; font-size: 16px;"
              >
                <p class="head2" style="font-family: Lora;">{{ testimonial.name }}</p>
                <p style="font-family: Hind Madurai; font-size: 18px;">
                  <img src="../assets/usc.png" alt="" />
                  {{ testimonial.message }}
                </p>
              </b-card>
            </b-col>
          </div>

          <img
            src="../assets/rarr.png"
            alt=""
            @click="next"
            style="cursor: pointer"
          />
        </div>
      </div>
    </b-container>



<!-- <b-container fluid class="mt-5 pb-2">
    <div class="services-container my-5">
      <div>

        <p style="font-family: Lora; font-size: 24px; font-weight: 400; margin-top: 70px; margin-bottom: 50px;">
          Explore MANE's Latest Insights: <strong> Trends, Ideas, and Inspiration </strong>
        </p>

        <b-row>

          <b-col cols="12" md="4">
            <div class="blog-container blog-img-zoom">
              <img src="../assets/blogs-page/b1-img.png" alt="" class="img-fluid blog-img" />
              <div class="blog-overlay">Importance of choosing the right site for residential construction</div>
            </div>
          </b-col>

          <b-col cols="12" md="4">
            <div class="blog-container blog-img-zoom">
              <img src="../assets/blogs-page/b2-img.jpg" alt="" class="img-fluid blog-img" />
              <div class="blog-overlay">Importance of choosing the right site for residential construction</div>
            </div>
          </b-col>

          <b-col cols="12" md="4" class="text-md-left text-center">
            
            <b-row style="margin-top: 2%; margin-bottom: 2%; text-align: left !important; padding-left: 1.5vh;">
              <p class="blog-body">
                Explore our curated collection of architectural insights, 
                from innovative design solutions to inspiring project showcases. 
                Dive into expert advice and stay current with the latest trends in 
                construction and architecture.
              </p>
            </b-row>

            <b-row style="margin-top: 2%; margin-bottom: 2%; text-align: left !important; padding-left: 1.5vh;">
              <router-link :to="{ name: 'blogs' }">
                <b-button class="button border-0 text-white text-uppercase" style="background-color: black;">
                  <span class="small" style="font-family: Montserrat; padding-left: 0.75rem;">
                    Discover more
                  </span>
                </b-button>
              </router-link>
            </b-row>
            
          </b-col>
        </b-row>
      </div>

    </div>
  </b-container> -->



<b-container fluid class="mt-5 pb-4">
    <div class="services-container my-5">
      <div>

        <p style="font-family: Lora; font-size: 24px; font-weight: 400; margin-top: 70px; margin-bottom: 50px;">
          Explore MANE's Latest Insights: <strong> Trends, Ideas, and Inspiration </strong>
        </p>

        <b-row>

          <b-col cols="12" md="7">
            <img src="../assets/blogs-page/b1-img.png" style="border-radius: 8px;" alt="" class="img-fluid blog-img-zoom" />
          </b-col>

          <b-col cols="12" md="5" class="text-md-left text-center">
            
            <b-row style="padding-left: 1.5vh; margin-top: 2%; margin-bottom: 2%; text-align: left !important;">
              <p class="blog-subhead">
                Importance of choosing the right site for residential construction.
              </p>

              <p class="blog-body" style="margin-top: 2%; font-size: 18px;">
                Choosing the right site for residential construction is a critical decision 
                that can significantly influence the success and satisfaction of 
                a building project. 
                Here’s why it’s so important:
                <br>
                1. Quality of Life
                <br>
                Location: The location of a residential construction site greatly affects 
                the daily life of
                . . . .
                <router-link :to="{ name: 'blog1' }">
                  <span style="font-family: Montserrat; color: #2c3e50; cursor: pointer;"> more </span>
                </router-link>
              </p>
            </b-row>

            <b-row style="padding-left: 1.5vh; margin-top: 2%; margin-bottom: 2%;">
              <div style="text-align: start;">
                <router-link :to="{ name: 'blogs' }">
                  <b-button class="button border-0 text-white text-uppercase" style="background-color: black;">
                    <span class="small" style="font-family: Montserrat; padding-left: 0.75rem;">Discover more</span>
                  </b-button>
                </router-link>
              </div>
            </b-row>
            
          </b-col>
        </b-row>
      </div>

    </div>
  </b-container>


    
  <b-container  style="background-color: black !important" fluid>
    
    <b-row >
      <b-col md="4"  class="text-center text-md-left footer" style="font-size: 12px;">
        <div class="foot1"> 
          <img
          src="../assets/logo2.png"
          alt="Logo"
          height="100" width="100"
          class="mt-3 mt-md-5 ml-md-3"
          style="margin-left:30px"
         
        />
          <div class="social-media">
            <img
                src="../assets/SOCIAL MEDIA/Group.png" width="40px" height="40px"
                alt=""
                class="p-2"
              />
              <img
                src="../assets/SOCIAL MEDIA/face.png" width="60px" height="40px"
                alt=""
                class="p-2"
              />
              <img
                src="../assets/SOCIAL MEDIA/Path.png" width="40px" height="40px"
                alt=""
                class="p-2"
              />
          </div>        
      </div>

        <div class="foot2"> <p
          class="mt-5 mt-md-5 ml-md-3 ft2-txt">
     
          We create create for comfort,
          beauty, and quality.
        </p>
        </div>
      </b-col>
    
        <b-col md="4" class="text-center text-md-left foot_getintouch">
        
      <div class="get">
        <p>
          <strong> Get in Touch</strong>
         </p>
      </div> 
     

      <p style="margin-bottom: 0rem;">#93, Rajagopal Nagar main Road, Next to Veeranjaneya Temple, Bengaluru, 560058</p><br>
      <p style="margin-bottom: 0rem;">consultant@manedesign.in</p><br>
      <p style="margin-bottom: 0rem;">+91 6362609612 </p>

      
      </b-col>


      <b-col md="4" class="text-center text-md-left">
        <div
          class="mt-3 mt-md-5 d-flex justify-content-center align-items-center"
        >
          <form action="https://formspree.io/f/xyzgzjqq" method="POST">
            <b-row class="mb-2">
              <input
                type="text"
                name="Full Name"
                placeholder="Full Name"
                class="inputmob"
                style="
                 padding: 7px;
                    width: 100%;
                    background-color: black;
                    color: white;
                    border-bottom: 1px solid white;
                    font-size:14px;
                "
              />
            </b-row>
            <b-row class="mb-2">
              <input
                type="number"
                name="Mobile Number"
                placeholder="Mobile Number"
                class="inputmob"
                style="
                  padding: 7px;
                    width: 100%;
                    background-color: black;
                    color: white;
                    border-bottom: 1px solid white;
                    font-size:14px;
                "
              />
            </b-row>
            <b-row class="mb-2">
              <input
                type="text"
                name="Location"
                placeholder="Location"
                class="inputmob"
                style="
                  padding: 7px;
                    width: 100%;
                    background-color: black;
                    color: white;
                    border-bottom: 1px solid white;
                    font-size:14px;
                "
              />
            </b-row>
            <b-row>
              <button
                type="submit"
                style="
                  background-color: white;
                  margin:auto;
                  margin-top: 20px;
                  width: 50%;
                  border: none;
                "
              >
                <span style="font-size: 12px; color: black; font-weight: 600; "
                  >Submit</span
                >
              </button>
            </b-row>
          </form>
        </div>
      </b-col>
    </b-row>
  
    <b-row class="mt-3">
      <b-col>
        <hr style="color: white" />
        <p class="text-center text-md-left" style="color: white; font-size: 10px;">
          © 2015 - 2024 All Rights Reserved. Mane Design
        </p>
      </b-col>
    </b-row>
  </b-container>
</div>
  <!-- Responsive Container -->
 
  <!-- Additional content should come here -->
</template>
<script>



  export default {
    data() {
      return {
        services: [
        {
          title: "Architectural Design",
          image: require("../assets/SERVICES/s1.jpg"),
          description: "Our architectural design services don't just build structures; they weave dreams into reality. From concept to creation, we sculpt spaces that tell stories, embracing innovation, functionality, and aesthetics. Explore our portfolio of innovative designs and witness how our passion for architecture transforms blueprints into breathtaking reality. Step into a world where every line drawn is a step closer to turning your vision into a beautifully crafted space.",
          hover: false
        },
        {
          title: "Structural Design",
          image: require("../assets/SERVICES/s3.jpg"),
          description: "At our core, we engineer more than just structures; we lay the groundwork for aspirations to soar. Our structural                    design services are the bedrock of every visionary project.Combining expertise and innovation, we ensure every structure stands strong and true. From initial concept to the final blueprint, we create the framework for remarkable spaces that stand the test of time",
          hover: false
        },
        {
          title: "Electrical layout",
          image: require("../assets/SERVICES/s4.jpg"),
          description: " Invisible yet indispensable, our electrical and plumbing                    layouts are the unsung heroes behind every functional space.                    We intricately map out the veins of power and water,                    ensuring seamless functionality without compromising design.                    From switches to pipes, our detailed planning ensures a                    smooth, efficient, and problem-free experience.",
          hover: false
        },
        {
          title: "Interior Designs",
          image: require("../assets/SERVICES/s5.jpg"),
          description: "Our interior design services redefine spaces, balancing                    aesthetics and comfort. From concept to execution, we curate                    environments that reflect your style and personality. Our                    team's keen eye for detail and passion for creating inviting                    spaces ensure that every corner tells a story.",
          hover: false
        },
        {
          title: "3D Visualization",
          image: require("../assets/SERVICES/s2.jpg"),
          description: "Our 3D visualization services bring your ideas to life                    before they're built. Explore your space in stunning,                    lifelike detail. With cutting-edge technology, we create                    virtual environments where your vision leaps off the pageand into immersive, realistic views. Experience the power of visualizing your dream space before it's even constructed.",
          hover: false
        },
        {
          title: "Plumbing Layout",
          image: require("../assets/SERVICES/s6.jpg"),
          description: "Invisible yet indispensable, our electrical and plumbing                    layouts are the unsung heroes behind every functional space.                    We intricately map out the veins of power and water,                    ensuring seamless functionality without compromising design.                    From switches to pipes, our detailed planning ensures a                    smooth, efficient, and problem-free experience.",
          hover: false
        },
        // Add other services here...
      ],
      approachSteps: [
      {
        items: [
          { image: require('@/assets/ICONS/mc.png'), text: 'First meeting consultation', width: '40%' },
          { image: require('@/assets/ICONS/m.png'), text: 'Measurements', width: '45%' },
        ],
      },
      {
        items: [
          { image: require('@/assets/ICONS/as.png'), text: 'Agreement Signing', width: '54%' },
          { image: require('@/assets/ICONS/spl.png'), text: 'Space-planning layouts', width: '34%' },
        ],
      },
      {
        items: [
          { image: require('@/assets/ICONS/3d.png'), text: 'Interior photo-realistic 3D visualizations', width: '25%' },
          { image: require('@/assets/ICONS/ct.png'), text: 'Complete set of Technical documentation and specifications', width: '40%' },
        ],
      },
      {
        items: [
          { image: require('@/assets/ICONS/pro.png'), text: 'Procurement', width: '42%' },
          { image: require('@/assets/ICONS/os.png'), text: 'On-site designer’s supervision', width: '34%' },
        ],
      },
    ],
    blogs: [
      {
        image: 'a11.png',
        title: 'WHY ONE SHOULD PREPARE STRUCTURAL DETAILS FROM PROFESSIONALS BEFORE STARTING CONSTRUCTION',
        size: 8, // Bootstrap column size
        clickHandler: this.blog1, // Method to call on click
      },
      {
        image: 'a12.png',
        title: 'WHY ELECTRICAL LAYOUT IS IMPORTANT FOR BUILDING',
        size: 4,
        clickHandler: this.blog2,
      },
      {
        image: 'a13.png',
        title: 'IMPORTANCE OF CHOOSING THE RIGHT SITE',
        size: 4,
        clickHandler: this.blog3,
      },
      {
        image: 'a14.png',
        title: 'WHY ONE SHOULD FINALISE ELEVATION FROM PROFESSIONALS BEFORE STARTING CONSTRUCTION',
        size: 8,
        clickHandler: this.blog4,
      },
    ],
    testimonials: [
      {
        name: "Vignesh Kuldeep", 
        message: "Their architectural design services transform visions into tangible spaces. From initial concept to final execution, they craft environments that resonate with purpose and narrative."
      },
      {
        name: "Samantha L",
        message: "The team’s approach to design goes beyond mere construction; they create immersive experiences. Each project reflects a unique vision, bringing dreams to life with exceptional detail and creativity"
      },
      {
        name: "Raju Patel",
        message: "Working with them has been a game changer. Their architectural expertise not only shapes buildings but also enhances the way we live and interact within those spaces. Every design feels and inspired."
      },
      {
        name: "Anjali Mehta",
        message: "Their architectural vision is truly impressive. They don’t just design buildings; they create welcoming spaces that inspire connection and creativity. Each project is a unique reflection of its surroundings."
      },
      {
        name: "Rohan Desai",
        message: "Working with their team has been an enriching experience. They combine innovation with practicality, resulting in designs that enhance everyday life."
      },
    ],
    currentIndex: 0,
    itemsPerPage: 3,
    // name: "Vignesh Kuldeep",
    //   messages: [
    //     "Our architectural design services don’t just build structures; they weave dreams into reality. From concept to creation, we sculpt spaces that tell stories",
    //     "Our architectural design services don’t just build structures; they weave dreams into reality. From concept to creation, we sculpt spaces that tell stories",
    //     "Our architectural design services don’t just build structures; they weave dreams into reality. From concept to creation, we sculpt spaces that tell stories",
    //     "Our architectural design services don’t just build structures; they weave dreams into reality. From concept to creation, we sculpt spaces that tell stories",
    //     "Our architectural design services don’t just build structures; they weave dreams into reality. From concept to creation, we sculpt spaces that tell stories",
    //   ],
    // currentIndex: 0,
    // itemsPerPage: 3,
      }
    },
  //   computed: {
  //   visibleMessage() {
  //     return this.messages[this.currentIndex];
  //   },
  //   visibleMessages() {
  //     const visible = [];
  //     for (let i = 0; i < 3; i++) {
  //       visible.push(
  //         this.messages[(this.currentIndex + i) % this.messages.length]
  //       );
  //     }
  //     return visible;
  //   },
  // },
  computed: {
  visibleMessages() {
    const visible = [];
    for (let i = 0; i < this.itemsPerPage; i++) {
      visible.push(this.testimonials[(this.currentIndex + i) % this.testimonials.length]);
    }
    return visible;
  },
},
    methods:{
      projectDetails(){
      this.$router.push({ name: 'project1' });
    },
    blog1() {
    // Logic for blog 1
    console.log('Blog 1 clicked');
  },
  blog2() {
    // Logic for blog 2
    console.log('Blog 2 clicked');
  },
  blog3() {
    // Logic for blog 3
    console.log('Blog 3 clicked');
  },
  blog4() {
    // Logic for blog 4
    console.log('Blog 4 clicked');
  },
  prev() {
      if (this.currentIndex === 0) {
        this.currentIndex = this.messages.length - 1;
      } else {
        this.currentIndex -= 1;
      }
    },
    next() {
      if (this.currentIndex === this.messages.length - 1) {
        this.currentIndex = 0;
      } else {
        this.currentIndex += 1;
      }
    },
    }
  }
</script>
<style>
@import "@/assets/font.css";
body {
  overflow-x: hidden !important;
}

.our-services{
  color: black;  
      font-size: 32px;
      font-weight: 600;
      padding-top:20px;
}

.head1{
  color: black;  
  font-size: 32px;
  font-weight: 600;
 
}
.head2{
  color: black;  
  font-size: 18px;
  font-weight: 600;
}

.blg-txt{
  width: 100%; 
  height: 350px; 
  display: block;
   margin: 0 auto;
   margin-top: 20px; 
   font-size: 16px;
}

.testimonial-container {
  margin-top: 20px;
}
.testimonial-cards {
  overflow: hidden;
}
.testimonial {
  margin: 0 10px;
}
.pp3{
  color: white;
  font-family: Lora;
  font-size: 2.2vw;
  text-align: left;
  margin-left: 40px;
  padding-top: 5%;
}
.pp5{
  color: white;
  font-family: Hind Madurai;
  font-weight: 300;
  font-size: 1.3vw;
  text-align: left;
  margin-left: 40px;
  /* margin-top:5%; */
}
.why{
  margin-top: 80px;
}


.h-100{
  width:98%;
}

.test{
  padding:40px auto;
  padding-bottom: 40px;
}

.footer {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  column-gap: 20px; /* Space between the two columns */
  align-items: start; /* Align items at the top */
  padding-top:2rem;
}

.foot1 {
  display: flex;
  flex-direction: column;
  row-gap: 20px; /* Space between the column items */
  margin-top:0px;
}

.foot2 {
  font-size: 14px;
  font-weight: 500;
  color: white;
  text-align: left;
  margin-top:0px ;
  /* Removed margins to avoid extra space or overlap */
}



.ft2-txt{
  margin-left:20% ;
 
}


.foot_getintouch{
  color:white;
  padding-top: 43px;

}

.get{
  font-size: 16px;
   font-weight: 600;
    color: white;
    padding-bottom: 16px;
   text-align: center;
}

.social-media{
  display:flex;
  flex-direction: row;
  margin-left: 30px;
  margin-top:20px;
  
}





</style>
<style scoped>
@import "@/assets/font.css";
.sticky-button {
  background-color: #ffffff;
  border-radius: 10px 0px 0px 10px;
  z-index: 111;
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.sticky-button img {
  padding: 6px 12px;

  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.sticky-button button:hover {
  background-color: #2a2c2e;
}

@media(max-width:1050px){
  .foot1 {
    display: flex;
    flex-direction: column;
    row-gap: 20px; /* Space between the column items */
    padding-top:40px;
  }
  
  .foot2 {
    font-size: 14px;
    font-weight: 500;
    color: white;
    text-align: left;
    padding-top:40px;
  }  
}


@media(max-width:960px) {
  
  .footer{
    display:grid;
    flex-direction: column;

  }
    
}

@media (max-width: 768px) {
  .hide {
    display: none !important;
  }
  .moblogo{
    margin-right: 0px !important;
  }
  .footer{
    display:grid;
    flex-direction: column;
    column-gap: 0;

  }
 
 .foot2{
  display: block;
  padding-left: 0px;
  margin-top:0px;
  margin-left:0px;
 }



}
@media (max-width: 1200px) {
  .responsive-text {
    font-size: 0rem;
    margin-left: 20px;
  }
}
.responsive-image {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  max-height: 80vh;
}

@media (max-width: 1260px) {
  .responsive-image {
    max-height: 60vh;
  }
}

@media (max-width: 767px) {
  .responsive-image {
    max-height:50vh;
  }

.why-img{
  width:100%;
  height:0px;
}


}

@media (max-width: 575px) {
  .responsive-image {
    max-height: 40vh;
  }
}

.left-align {
  text-align: center !important;
}

.btn.left-align {
  display: flex;
  justify-content: center;
  padding-left: 0 !important;
}

@media (min-width: 992px) {
  .content-wrapper {
    text-align: left;
    margin-left: 10vh;
  }
}

@media (max-width: 767px) {
  .text-content {
    margin-top: 0;
  }
}

.btn {
  display: inline-flex;
  justify-content: center;
}

@media (min-width: 992px) {
  .btn {
    justify-content: flex-start;
    padding-left: 0 !important;
  }
}

.navbar-logo {
  height: 4rem;
  max-height: 8rem;
  margin-left: 20px;
}

.nav-button {
  border: 1px solid black;
  color: black;
  border-radius: 0;
}

.nav-button:hover , .nav-button:active, .nav-button:visited {
  background-color: #6e6e6e;
  border: 1px solid black;
}

.top-right-buttons {
  position: absolute;
  top: 1rem;
  right: 2rem;
}

.top-right-buttons .nav-button {
  margin-left: 1rem; /* Adds space between buttons */
}

@media (min-width: 992px) {
  .navbar-logo {
    height: 6rem;
  }
}

@media (max-width:640px){
  .h-100{
    width:96%;
  }

}

@media (max-width:400px){
  .h-100{
    width:92%;
  }

}
  

@media (max-width: 991.98px) {
  .navbar-nav {
    align-items: center;
  }
  
  .navbar-nav .nav-button {
    margin-bottom: 0.5rem; /* Adds space between buttons in mobile view */
  }
}
</style>

<style scoped>
.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}
.services-container {
  max-width: 1200px;
  margin: auto;
  padding: 0 15px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr)); /* Default for larger screens */
  gap: 20px;
}

/* Responsive adjustments for mobile view */
@media (max-width: 767px) {
  .services-grid {
    grid-template-columns: 1fr; /* Show one card in a row */
    gap: 0; /* Remove gaps between cards */

  }
}

.service-item {
  width: 100%; /* Ensure each card takes full width of the grid cell */
  margin: 0; /* Remove any margin */
  padding: 0; /* Remove any padding */
  margin-top: 15px;
}

.image-card {
  position: relative;
  overflow: hidden;
  border: none;
  border-radius: 0;
  height: 100%;
  
}

.image-container {
  position: relative;
  aspect-ratio: 1 / 1; /* Maintain square aspect ratio */
  overflow: hidden;
  width: 100%; /* Full width of the parent */
  height: auto; /* Allow height to adjust based on width */
  max-width: 380px; /* Optional: Set a max width for larger screens */
}

/* Responsive adjustments for mobile view */
@media (max-width: 768px) {
  .image-container {
    height: 250px; /* Adjust height for smaller screens */
  }
}

@media (max-width: 576px) {
  .image-container {
    height: 200px; /* Further adjust height for extra small screens */
  } 
  .footer{
    display:grid;
    flex-direction: column;

  }
  .foot1{
    margin:auto;
  }
 .foot2{
  display: block;
  margin-left: 0px;
  padding-left: 0px;
  margin-top:0px;
  margin-right:0px;
 }
 .ft2-txt{
  margin-left:0% !important;
  margin-top:5% ! important;
  text-align: center;

 }

}

.card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.card-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  transition: 0.5s ease;
}

.card-title {
  color: white;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.card-content {
  color: white;
  text-align: center;
  font-size: 0.9rem;
}

@media (min-width: 768px) {
  .card-overlay {
    height: auto;
    max-height: 30%;
  }

  .card-content {
    display: none;
  }

  .image-container:hover .card-overlay {
    height: 100%;
    max-height: 100%;
  }

  .image-container:hover .card-content {
    display: block;
  }

  .image-container:hover .card-img {
    transform: scale(1.1);
  }
}

@media (max-width: 767px) {
  .services-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .image-container {
    aspect-ratio: 1 / 1;
  }

 
  .card-overlay {
    height: auto;
    max-height: 30%;
  }

  .card-content {
    display: none;
  }

  .image-container:hover .card-overlay {
    height: 100%;
    max-height: 100%;
  }

  .image-container:hover .card-content {
    display: block;
    font-size: 0.8rem;
  }

  .card-title {
    font-size: 1rem;
  }

  .image-container:hover .card-img {
    transform: scale(1.1);
  }

  .pp3{
    font-size: 0.9rem;
    text-align: left;
    margin-left: 20px;
  }
  .pp5{
    font-size: 0.7rem;
    text-align: left;
    margin-left: 20px;
  }
  .why{
    margin-top: 40px;
  }

}


.inputcard{
          color: white;
          font-size: 16px;
          font-weight: 600;
          position: absolute;
          top: 85%;
          left:37%;
          transform: translate(-50%, -50%);
          text-align: left;
          background-color:rgba(0,0,0,0.1);
} 


@media (max-width: 480px) {
  .card-title {
    font-size: 0.9rem;
  }

  .card-content {
    font-size: 0.7rem;
  }

  .foot2{
    margin-right:4px;
    margin-top: 4px;
  }
}

@media (min-width:1540px){
  .head1{
    font-size:34px;
  }
  .head2{
    font-size: 24px;
  }
  .bdy-txt{
    font-size: 18px;
  }


}

@media (min-width:1024px){
  .head1{
    font-size:32px !important;
  }
  .head2{
    font-size: 20px !important;
  }
  .bdy-txt{
    font-size: 16px !important;
  }
}

@media((min-width:768px) and (max-width:1024px)){
  .head1{
    font-size:28px !important;
  }
  .head2{
    font-size: 18px !important;
  }
  .bdy-txt{
    font-size: 16px !important;
  }
}

@media(max-width:768px){
  .head1{
    font-size:24px !important;
  }
  .head2{
    font-size: 16px !important;
  }
  .bdy-txt{
    font-size: 14px !important;
  }
}





</style>
</style>
</style>
